import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { Select } from 'layout/Select';
import { updateWorkflow, trackWorkFlowChange } from 'admin/workflows/modules/workflows';
import { getOptions } from 'views/modules/options';

const UserSelect = ({ value, users, updateWorkflow, trackWorkFlowChange, getOptions, ...props }) => {
  useEffect(() => {
    const config = {
      name: 'recipients_users',
      url: 'users/?order_by=name&q=',
      valueKey: 'id',
      labelKey: 'name',
    };
    getOptions(config, '');
  }, [getOptions]);
  let items = [];
  if (users && users.loading === false) {
    items = [...users.options];
  }
  const onChange = users => {
    users = users || [];
    const { recipients = [] } = props.workflows.workflow.data;
    const type = 'user_id';
    trackWorkFlowChange(true);
    updateWorkflow({
      data: {
        recipients: [
          ...recipients.filter(x => x.type !== type),
          ...users.map(({ value, label }) => {
            return { type, value, label };
          }),
        ],
      },
    });
  };
  const isLoading = users === undefined || users.loading;
  return <Select isMulti isLoading={isLoading} selected={value} onChange={onChange} options={items} />;
};

function mapStateToProps({ options, workflows }) {
  return {
    users: options['recipients_users'],
    workflows,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ getOptions, updateWorkflow, trackWorkFlowChange }, dispatch);
}
export default connect(mapStateToProps, mapDispatchToProps)(UserSelect);
