import React from 'react';
import { Col, Row } from 'antd';
import { FormItem, Label, Section } from '@luxe/components';

const MaterialSettingsSection = props => {
  return (
    <>
      <Section title={'General'}>
        <Row style={{ width: '100%' }}>
          <Col span={12}>
            <FormItem name="description" label="Description" labelCol={{ span: 24 }}>
              <Label width={'100%'} />
            </FormItem>
          </Col>
        </Row>
      </Section>
    </>
  );
};

export default MaterialSettingsSection;
