import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from '@emotion/styled';
import { Card, Row, Slider, Button, Tooltip, Collapse, Radio, Layout } from 'antd';
import { DeleteOutlined, InfoCircleOutlined, LeftOutlined, RightOutlined, SaveOutlined } from '@ant-design/icons';
import { Select } from '@luxe/components';

import { generateMarks } from 'discover/modules/graphUtilities';
import AssetDiscoverDimension from 'assets/containers/AssetDetailView/AssetDiscoverSection/AssetDiscoverDimension';
import SaveModal from './SaveModal';
import DeleteModal from './DeleteModal';
import RelationshipStrengthInput from 'discover/containers/DiscoverNetworkView/RelationshipStrengthInput';
import {
  changeGraphMode,
  clearGraphFilters,
  getNetworkAssetGraph,
  showDeleteModal,
  showSaveModal,
  setFilterPanelVisibility,
  changeGraphView,
  updateGraphFilters,
  clearSearchedNode,
  NODE_COLOR_RISK_SCORE,
  NODE_COLOR_TIER,
  NODE_COLORS,
  clearIncidentDetails,
  clearSelectedNodeIdFromURL,
  NODE_COLOR_INCIDENT_RISK_SCORE,
  deselectNode,
  updateGraphNodeColor,
  clearSelectedView,
  updateDiscoveredFacilitiesToggle,
} from 'discover/modules/viz';
import useMixpanel from 'utils/hooks/Mixpanel';
import { useLocation, useHistory } from 'react-router-dom';
import { hideNotification } from 'common/components/Notification';
import DiscoverFacilitiesToggle from './DiscoverFacilitiesToggle';
import { cleanCodes } from 'assets/modules/graphs';
import { AsyncSelect } from 'views/components/Select';
import { clearAsyncOptions } from 'views/modules/options';
import { getCommodities } from 'modal/userprofile/modules/network';

const NetworkDiscoverFilters = styled(props => {
  const toolTipStyling = { backgroundColor: '#141414', fontSize: '14px', color: '#ffffff' };
  const { Panel } = Collapse;
  const {
    viewsNetworkGraph,
    saveModalVisible,
    deleteModalVisible,
    selectedView,
    filterPanelVisible,
    filters: graphFilters,
    incidentId,
    fetchedNodeIdFromURL,
  } = useSelector(store => store.networkGraph);
  const {
    max_depth: maxDepth,
    node_color: nodeColor,
    graphMode,
    countries,
    hs_codes,
    product,
    material,
    asset_group,
    risk,
    facilities,
    discover_facility,
    incident_risk,
    min_relationship_strength: minRelationshipStrength,
    max_relationship_strength: maxRelationshipStrength,
    commodities,
  } = graphFilters;
  const [newFilters, setNewFilters] = useState({
    max_depth: maxDepth,
    node_color: nodeColor,
    graphMode,
    countries,
    hs_codes,
    product,
    material,
    asset_group,
    risk,
    facilities,
    incident_risk,
    min_relationship_strength: null,
    max_relationship_strength: null,
    commodities,
  });
  const { risk_options } = useSelector(store => store.graphs);
  const { assetGroups } = useSelector(store => store.assetGroups);
  const { controlFormattedCountries } = useSelector(store => store.userNetwork);
  const materialsEnabled = useSelector(store => store.accountOrg?.accountOrg?.prefs?.materials?.enabled || false);
  const isDiscoverEnabled = useSelector(store => store.accountOrg?.accountOrg?.prefs?.discover?.enabled || false);
  const isRelationshipStrengthEnabled = useSelector(
    store => store.accountOrg?.accountOrg?.prefs?.discover?.relationship_strength_enabled || false,
  );
  const { commodities: commodityOptions } = useSelector(store => store.userNetwork);
  const commodity_pref = useSelector(store => store.accountOrg?.accountOrg?.prefs?.commodity_risk?.enabled);
  const [formattedCommodities, setFormattedCommodities] = useState([]);
  const dispatch = useDispatch();
  const mixpanelTrack = useMixpanel();
  const location = useLocation();
  const history = useHistory();
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    setNewFilters({
      max_depth: maxDepth,
      node_color: nodeColor,
      graphMode,
      countries,
      hs_codes,
      product,
      material,
      asset_group,
      risk,
      facilities,
      incident_risk,
      min_relationship_strength: minRelationshipStrength,
      max_relationship_strength: maxRelationshipStrength,
      commodities,
    });
  }, [
    maxDepth,
    nodeColor,
    graphMode,
    countries,
    hs_codes,
    product,
    material,
    asset_group,
    risk,
    facilities,
    incident_risk,
    minRelationshipStrength,
    maxRelationshipStrength,
    commodities,
  ]);

  const togglePanelVisibility = () => {
    dispatch(setFilterPanelVisibility(!filterPanelVisible));
  };

  const viewOptions = () => {
    return viewsNetworkGraph?.map(v => ({ value: v.id, label: v.name })) || { value: '', label: '' };
  };

  const viewSelectorValue = () => {
    if (fetchedNodeIdFromURL?.fname) {
      return { value: '', label: '' };
    } else {
      return selectedView ? { value: selectedView?.id, label: selectedView?.name } : [];
    }
  };

  const selectorChanged = event => {
    dispatch(clearSelectedNodeIdFromURL());
    const matchingView = viewsNetworkGraph?.find(obj => obj?.id === event.value);
    if (matchingView) {
      dispatch(changeGraphView(matchingView.id));
    }
  };

  const saveButtonClicked = () => dispatch(showSaveModal());
  const deleteButtonClicked = () => dispatch(showDeleteModal());

  const nodeColorChanged = color => {
    mixpanelTrack('Discover Filter Node Color Change');
    dispatch(updateGraphNodeColor(color));
  };
  const countrySelectorChanged = selected =>
    setNewFilters({ ...newFilters, countries: (selected ?? []).map(t => t.value) });
  const hsCodeSelectorChanged = selected =>
    setNewFilters({ ...newFilters, hs_codes: (selected ?? []).map(t => t.value) });
  const minRelationshipStrengthChanged = newValue => {
    setNewFilters(currentFilters => {
      // Don't allow a value outside of 0-1 bounds
      let newMinStrength = newValue;
      if (newValue <= 0) {
        newMinStrength = null;
      }
      if (newValue >= 1) {
        newMinStrength = 1;
      }
      // If the new minimum is greater than the current maximum and the maximum is not null,
      // update the maximum to match the new minimum. Otherwise, keep the current maximum.
      const newMaxStrength =
        currentFilters.max_relationship_strength !== null &&
        newMinStrength !== null &&
        newMinStrength > currentFilters.max_relationship_strength
          ? newMinStrength
          : currentFilters.max_relationship_strength;

      return {
        ...currentFilters,
        min_relationship_strength: newMinStrength,
        max_relationship_strength: newMaxStrength,
      };
    });
  };

  const maxRelationshipStrengthChanged = newValue => {
    setNewFilters(currentFilters => {
      // Don't allow a value outside of 0-1 bounds
      let newMaxStrength = newValue;
      if (newValue <= 0) {
        newMaxStrength = 0;
      }
      if (newValue >= 1) {
        newMaxStrength = null;
      }
      // If the new maximum is less than the current minimum and the minimum is not null,
      // update the minimum to match the new maximum. Otherwise, keep the current minimum.
      const newMinStrength =
        currentFilters.min_relationship_strength !== null &&
        newMaxStrength !== null &&
        newMaxStrength < currentFilters.min_relationship_strength
          ? newMaxStrength
          : currentFilters.min_relationship_strength;

      return {
        ...currentFilters,
        max_relationship_strength: newMaxStrength,
        min_relationship_strength: newMinStrength,
      };
    });
  };

  const facilitiesSelectorChanged = term =>
    setNewFilters({ ...newFilters, facilities: term ? term.map(t => t.value) : [] });
  const productSelectorChanged = selected =>
    setNewFilters({ ...newFilters, product: (selected ?? []).map(t => t.value) });
  const materialSelectorChanged = selected =>
    setNewFilters({ ...newFilters, material: (selected ?? []).map(t => t.value) });
  const assetGroupSelectorChanged = selected =>
    setNewFilters({ ...newFilters, asset_group: (selected ?? []).map(t => t.value) });
  const onCommoditiesChanged = sel => {
    setNewFilters({ ...newFilters, commodities: (sel ?? []).map(t => t.value) });
  };
  const riskSelectorChanged = selected => setNewFilters({ ...newFilters, risk: (selected ?? []).map(t => t.value) });
  const tierDepthSliderChanged = x => setNewFilters({ ...newFilters, max_depth: x });

  const incidentRiskSelectorChanged = selected => {
    dispatch(updateGraphFilters({ incident_risk: selected ? selected.map(t => t.value) : [] }));
  };

  const abbreviateLabel = label => (label.length > 48 ? `${label.slice(0, 48)}...` : label);

  const countrySelectorValue = () =>
    controlFormattedCountries?.filter(entry => newFilters.countries.includes(entry.value));

  const returnAssetGroupValues = () =>
    assetGroups
      ?.filter(v => newFilters.asset_group.includes(v.id))
      .map(v => ({ label: abbreviateLabel(v.name), value: v.id }));

  const returnCommoditiesValues = () =>
    formattedCommodities
      ?.filter(v => newFilters.commodities.includes(v.value))
      .map(v => ({ label: abbreviateLabel(v.label), value: v.value }));

  const returnRiskValues = () =>
    risk_options
      ?.filter(hs => newFilters.risk.includes(hs.value))
      .map(hs => ({ label: abbreviateLabel(hs.label), value: hs.value }));

  const returnIncidentRiskValues = () =>
    risk_options
      ?.filter(hs => incident_risk.includes(hs.value))
      .map(hs => ({ label: abbreviateLabel(hs.label), value: hs.value }));

  const discoveredFacilitiesToggleChange = val => {
    dispatch(updateDiscoveredFacilitiesToggle({ discover_facility: val === 'show' ? true : false }));
  };

  const applyButtonClicked = () => {
    hideNotification('emptyAssetGraph');
    const filters = { ...graphFilters, ...newFilters };
    if (incidentId) {
      filters.max_depth = 3;
    }
    dispatch(deselectNode());
    dispatch(updateGraphFilters(filters));
    dispatch(
      getNetworkAssetGraph(filters, incidentId, fetchedNodeIdFromURL?.fname, materialsEnabled && isDiscoverEnabled),
    );
  };

  const refreshButtonClicked = () => {
    if (incidentId) {
      dispatch(clearIncidentDetails());
      const query = new URLSearchParams(location?.search);
      query.delete('inc_id');
      history.push({ search: query.toString() });
    }
    dispatch(clearGraphFilters());
    dispatch(clearSearchedNode());
    dispatch(clearSelectedView());
  };

  const scrollPanelHeaderToTop = e => {
    if (e) {
      setTimeout(() => {
        document
          .querySelector('.filter-items')
          .scrollTo({ top: document.getElementById(e).offsetTop, behavior: 'smooth' });
      }, 200);
    }
  };

  useEffect(() => {
    const populateChild = arr => {
      return arr.map(commodity => {
        if (commodity.children?.length > 0) {
          const data = commodity.children?.map(x => {
            return {
              label: x.name,
              value: x.id,
            };
          });
          populateChild(commodity.children);
          return data;
        } else {
          return [];
        }
      });
    };

    if (commodityOptions?.length && !formattedCommodities?.length) {
      const options = populateChild(commodityOptions)?.flat();
      setFormattedCommodities(options);
    }
  }, [commodityOptions, formattedCommodities]);

  useEffect(() => {
    if (props.statusLoading && filterPanelVisible) {
      setVisible(true);
      dispatch(setFilterPanelVisibility(false));
    } else if (!props.statusLoading && visible) {
      setVisible(false);
      dispatch(setFilterPanelVisibility(true));
    }
  }, [props.statusLoading, dispatch, filterPanelVisible, visible]);

  const HScodeConfig = useMemo(
    () => ({
      selectedOptions: newFilters.hs_codes,
      params: {
        max_depth: maxDepth,
        country: countries,
        asset_group,
        risk,
        facilities,
        product,
        material,
      },
      url: 'graph/facilities/hscodes/',
      name: 'hs_codes',
      limit: 10,
      parseOptions: cleanCodes,
      uniqBy: 'label',
    }),
    [maxDepth, countries, asset_group, risk, facilities, product, material, newFilters.hs_codes],
  );
  const facilityConfig = useMemo(
    () => ({
      selectedOptions: newFilters.facilities,
      params: {
        max_depth: maxDepth,
        country: countries,
        product,
        material,
        hscode6: hs_codes,
      },
      url: 'graph/facilities/search/',
      name: 'facilities',
      limit: 10,
      valueKey: 'id',
      labelKey: 'name',
    }),
    [maxDepth, product, material, hs_codes, countries, newFilters.facilities],
  );
  const productsConfig = useMemo(
    () => ({
      selectedOptions: newFilters.product,
      params: {
        max_depth: maxDepth,
        country: countries,
        asset_group,
        material,
        risk,
        facilities,
        hscode6: hs_codes,
      },
      url: 'graph/facilities/products/',
      name: 'products',
      limit: 10,
      valueKey: 'id',
      labelKey: 'name',
    }),
    [maxDepth, countries, asset_group, risk, facilities, material, hs_codes, newFilters.product],
  );
  const materialsConfig = useMemo(
    () => ({
      selectedOptions: newFilters.material,
      params: {
        max_depth: maxDepth,
        country: countries,
        asset_group,
        risk,
        facilities,
        product,
        material,
        hscode6: hs_codes,
      },
      url: 'graph/facilities/materials/',
      name: 'materials',
      limit: 10,
      parseOptions: d => d.map(e => ({ label: `${e.id} ${e.name}`, value: e.uuid })),
    }),
    [maxDepth, countries, asset_group, risk, facilities, product, material, hs_codes, newFilters.material],
  );

  useEffect(() => {
    dispatch(clearAsyncOptions('hs_codes'));
  }, [dispatch, hs_codes, maxDepth, countries, asset_group, risk, facilities, product, material]);
  useEffect(() => {
    dispatch(clearAsyncOptions('facilities'));
  }, [dispatch, facilities, maxDepth, countries, product, material, hs_codes]);
  useEffect(() => {
    dispatch(clearAsyncOptions('products'));
  }, [dispatch, product, maxDepth, countries, asset_group, material, risk, facilities, hs_codes]);
  useEffect(() => {
    dispatch(clearAsyncOptions('materials'));
  }, [dispatch, material, maxDepth, countries, asset_group, risk, facilities, product, material, hs_codes]);

  useEffect(() => {
    dispatch(getCommodities());
  }, [dispatch]);

  return (
    <Layout.Sider className={props.className} collapsedWidth={0} collapsed reverseArrow>
      {saveModalVisible && <SaveModal unappliedFilters={newFilters} />}
      {deleteModalVisible && <DeleteModal />}
      <Row className={`network-filter ${filterPanelVisible}`}>
        <Card className={'filter-card filter-save'}>
          <div className="filter-toggle">
            <Button onClick={togglePanelVisibility} disabled={props.statusLoading}>
              {filterPanelVisible ? (
                <RightOutlined style={{ color: `white` }} />
              ) : (
                <LeftOutlined style={{ color: `white` }} />
              )}
            </Button>
          </div>
          <Row className={'filter-saved-filters'}>
            <div className={'filter-select-beside-buttons'}>
              <Select
                className={'filter-select'}
                options={viewOptions()}
                onChange={selectorChanged}
                value={viewSelectorValue()}
                placeholder={'Select a Saved View'}
              />
            </div>
            <div className={'filter-select-pad-cell'}></div>
            <Button className={'filter-view-buttons'} variant="menu" type="link" onClick={saveButtonClicked}>
              <SaveOutlined className={'filter-view-buttons'} />
            </Button>
            {selectedView && (
              <Button
                className={'filter-view-buttons'}
                variant="menu"
                type="link"
                disabled={!selectedView}
                onClick={deleteButtonClicked}
              >
                <DeleteOutlined className={'filter-view-buttons'} />
              </Button>
            )}
          </Row>
        </Card>
        <label className={'filter-title'}>Filters</label>
        <Card className={'filter-card filter-node-color'}>
          <label>Node Color</label>
          <Tooltip
            overlayStyle={{ maxWidth: 'fit-content' }}
            placement="bottom"
            title={
              newFilters.node_color === NODE_COLOR_TIER ? (
                <TierTooltipIcons />
              ) : newFilters.node_color === NODE_COLOR_RISK_SCORE ? (
                <RiskTooltipIcons />
              ) : (
                <IncidentRiskTooltipIcons />
              )
            }
          >
            <InfoCircleOutlined />
          </Tooltip>
          <Radio.Group
            className="discover-graph-mode"
            value={newFilters.node_color}
            buttonStyle="solid"
            onChange={e => nodeColorChanged(e.target.value)}
            size="small"
          >
            <Radio.Button value={NODE_COLOR_INCIDENT_RISK_SCORE}>Incident Risk</Radio.Button>
            <Radio.Button value={NODE_COLOR_RISK_SCORE}>Strategic Risk</Radio.Button>
            <Radio.Button value={NODE_COLOR_TIER}>Tier</Radio.Button>
          </Radio.Group>
        </Card>
        {materialsEnabled && isDiscoverEnabled && (
          <Card className={'filter-card filter-dimension'}>
            <label>Discover Facilities</label>
            <DiscoverFacilitiesToggle
              value={discover_facility}
              discoveredFacilitiesToggleChange={discoveredFacilitiesToggleChange}
            />
          </Card>
        )}
        <Card className={'filter-card filter-dimension'}>
          <label>View</label>
          <AssetDiscoverDimension
            value={graphMode}
            setNodesRefreshed={() => {}}
            setGraphMode={v => {
              mixpanelTrack(`Discover Filter Change view - ${v}`, { view: v });
              dispatch(changeGraphMode(graphMode, v));
            }}
          />
        </Card>
        <Card className={'filter-card filter-items'}>
          <Collapse accordion onChange={scrollPanelHeaderToTop}>
            <Panel header={'Countries'} key={'location-filter'} forceRender={true} id={'location-filter'}>
              <label>Countries</label>
              <Tooltip
                title="Returns downstream relationships for facilities that are located in the selected Countries."
                overlayInnerStyle={toolTipStyling}
              >
                <InfoCircleOutlined />
              </Tooltip>

              <Select
                isMulti={true}
                options={controlFormattedCountries}
                onChange={countrySelectorChanged}
                name="countries"
                className={'filter-select'}
                value={countrySelectorValue()}
                menuPlacement="bottom"
              />
            </Panel>
            <Panel header={'Tier'} key={'tier-filter'} id={'tier-filter'}>
              <label>Tier</label>
              <Tooltip
                title="Returns upstream relationships for the selected tiers."
                overlayInnerStyle={toolTipStyling}
              >
                <InfoCircleOutlined />
              </Tooltip>
              <Slider
                marks={generateMarks(3)}
                min={1}
                max={3}
                tooltipVisible={false}
                onChange={tierDepthSliderChanged}
                value={newFilters.max_depth}
                name={'max_depth'}
              />
            </Panel>
            {isDiscoverEnabled && (
              <Panel header={'HS Codes'} key={'hscode-filter'} id={'hscode-filter'}>
                <div className={'filter-section-label-tooltip'}>
                  <label>HS Codes</label>
                  <Tooltip
                    title="Returns downstream relationships for the selected HS Code values."
                    overlayInnerStyle={toolTipStyling}
                  >
                    <InfoCircleOutlined />
                  </Tooltip>
                </div>
                <AsyncSelect
                  showSearch
                  isMulti
                  className={'filter-hscode'}
                  name="hs_codes"
                  onChange={hsCodeSelectorChanged}
                  menuPlacement="bottom"
                  config={HScodeConfig}
                />
              </Panel>
            )}
            {commodity_pref && (
              <Panel header={'Commodities'} key={'commodities-filter'} id={'commodities-filter'}>
                <div className={'filter-section-label-tooltip'}>
                  <label>Commodities</label>
                  <Tooltip
                    title="Returns downstream relationships for facilities tagged with the selected commodity values."
                    overlayInnerStyle={toolTipStyling}
                  >
                    <InfoCircleOutlined />
                  </Tooltip>
                </div>
                <Select
                  showSearch
                  className={'filter-commodities'}
                  isMulti={true}
                  options={formattedCommodities}
                  onChange={onCommoditiesChanged}
                  name="commodity"
                  value={returnCommoditiesValues()}
                  menuPlacement="bottom"
                />
              </Panel>
            )}
            <Panel header={'Facilities'} key={'facilities-filter'} id={'facilities-filter'}>
              <label>Facilities</label>
              <Tooltip
                title="Returns downstream relationships for the selected Facilities."
                overlayInnerStyle={toolTipStyling}
              >
                <InfoCircleOutlined />
              </Tooltip>
              <AsyncSelect
                showSearch
                isMulti
                className={'filter-hscode'}
                name="facilities"
                onChange={facilitiesSelectorChanged}
                menuPlacement="bottom"
                config={facilityConfig}
              />
            </Panel>
            <Panel header={'Facility Groups'} key={'asset-filter'} id={'asset-filter'}>
              <label>Facility Groups</label>
              <Tooltip
                title="Returns downstream relationships for the selected facility groups."
                overlayInnerStyle={toolTipStyling}
              >
                <InfoCircleOutlined />
              </Tooltip>
              <Select
                showSearch
                className={'filter-asset-group'}
                isMulti={true}
                options={assetGroups?.map(ag => ({
                  label: `${ag.name}`,
                  value: ag.id,
                }))}
                onChange={assetGroupSelectorChanged}
                name="assetGroups"
                value={returnAssetGroupValues()}
                menuPlacement="bottom"
              />
            </Panel>
            {materialsEnabled && (
              <Panel header={'Final Products'} key={'final-products-filter'} id={'final-products-filter'}>
                <label>Final Products</label>
                <Tooltip
                  title="
Returns upstream and downstream relationships for the selected Final Products."
                  overlayInnerStyle={toolTipStyling}
                >
                  <InfoCircleOutlined />
                </Tooltip>
                <AsyncSelect
                  showSearch
                  isMulti
                  className={'filter-products'}
                  name="products"
                  onChange={productSelectorChanged}
                  menuPlacement="bottom"
                  config={productsConfig}
                />
              </Panel>
            )}
            {materialsEnabled && (
              <Panel header={'Materials'} key={'material-filter'} id={'material-filter'}>
                <label>Materials</label>
                <Tooltip
                  title="Returns upstream and downstream relationships for the selected Material values."
                  overlayInnerStyle={toolTipStyling}
                >
                  <InfoCircleOutlined />
                </Tooltip>
                <AsyncSelect
                  showSearch
                  isMulti
                  className={'filter-materials'}
                  name="materials"
                  onChange={materialSelectorChanged}
                  menuPlacement="bottom"
                  config={materialsConfig}
                />
              </Panel>
            )}
            <Panel header={'Strategic Risk Level'} key={'risk-filter'} id={'risk-filter'}>
              <label>Strategic Risk Level</label>
              <Tooltip
                title="Returns downstream relationships for the selected Strategic Risk Levels."
                overlayInnerStyle={toolTipStyling}
              >
                <InfoCircleOutlined />
              </Tooltip>
              <Select
                showSearch
                className={'filter-risk'}
                isMulti={true}
                options={risk_options}
                onChange={riskSelectorChanged}
                name="risk"
                value={returnRiskValues()}
                menuPlacement="bottom"
              />
            </Panel>
            <Panel header={'Incident Risk Level'} key={'incident_risk-filter'} id={'incident_risk-filter'}>
              <label>Incident Risk Level</label>
              <Tooltip
                title="Returns downstream relationships for the selected Incident Risk Levels."
                overlayInnerStyle={toolTipStyling}
              >
                <InfoCircleOutlined />
              </Tooltip>
              <Select
                showSearch
                className={'filter-risk'}
                isMulti={true}
                options={risk_options}
                onChange={incidentRiskSelectorChanged}
                name="incident_risk"
                value={returnIncidentRiskValues()}
                menuPlacement="bottom"
              />
            </Panel>
            {isRelationshipStrengthEnabled && (
              <Panel
                header={'Relationship Strength'}
                key={'relationship-strength-filter'}
                id={'relationship-strength-filter'}
              >
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <RelationshipStrengthInput
                    label="Minimum"
                    value={
                      isNaN(newFilters.min_relationship_strength) || newFilters.min_relationship_strength === null
                        ? '0.00'
                        : parseFloat(newFilters.min_relationship_strength).toFixed(2)
                    }
                    onChange={value => minRelationshipStrengthChanged(parseFloat(value))}
                  />
                  <RelationshipStrengthInput
                    label="Maximum"
                    value={
                      isNaN(newFilters.max_relationship_strength) || newFilters.max_relationship_strength === null
                        ? '1.00'
                        : parseFloat(newFilters.max_relationship_strength).toFixed(2)
                    }
                    onChange={value => maxRelationshipStrengthChanged(parseFloat(value))}
                  />
                </div>
              </Panel>
            )}
          </Collapse>
        </Card>
        <Card className={'filter-card refresh'}>
          <Button onClick={applyButtonClicked} className="filter-refresh">
            Apply / Refresh
          </Button>
          <Button onClick={refreshButtonClicked} className="filter-refresh">
            Clear
          </Button>
        </Card>
      </Row>
    </Layout.Sider>
  );
})`
  .network-filter {
    background-color: #212121;
    border: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    height: auto;
    position: absolute;
    right: -350px;
    top: 0px;
    width: 350px;
    z-index: 50;
  }

  .ant-collapse {
    background-color: #2e2e2e;
    border: none;

    .ant-collapse-item {
      border-bottom: 0;

      .ant-collapse-header {
        background-color: #212121;

        .ant-collapse-header-text {
          color: #ffffff;
        }
      }

      .ant-collapse-content {
        background-color: #2e2e2e;
        border-top: 0;

        .ant-collapse-content-box {
          background-color: #2e2e2e;
          height: 60vh;
          overflow-x: hidden;
          overflow-y: auto;
        }
        .filter-items::-webkit-scrollbar {
          width: 0.6em;
        }
        .filter-items::-webkit-scrollbar-track {
          background: transparent;
        }
        .filter-items::-webkit-scrollbar-thumb {
          background-color: #ffffff;
          border-radius: 30px;
          border: 1px solid black;
        }
      }
      &.ant-collapse-item-active#tier-filter {
        .ant-collapse-content-box {
          height: 20vh;
          .ant-slider {
            width: 90%;
          }
        }
      }
    }
    .ant-collapse-item.ant-collapse-item-active {
      .ant-collapse-header {
        position: sticky;
        top: 0px;
        z-index: 100;
      }
    }
  }

  .filter-checkbox-list {
    margin-top: 15px;
    width: 100%;

    .ant-checkbox-group-item {
      margin: 3px 0;
      position: relative;
      width: 100%;

      span {
        font-size: 16px;
        left: 0;
        padding: 0;
        position: absolute;

        &.ant-checkbox {
          left: unset;
          right: 5%;
        }
      }
    }
  }

  .filter-select {
    width: 100%;
  }

  .filter-select input,
  .filter-hscode input {
    color: #ffffff !important;
  }

  .filter-select-beside-buttons {
    width: 200px;
  }

  .filter-select-pad-cell {
    width: 25px;
  }

  .filter-select input,
  .filter-hscode input {
    color: #ffffff !important; // this is why inline styles are evil
  }

  .filter-toggle {
    margin-left: -50px;
    margin-top: 7px;
    position: absolute;
    width: 35px;
    height: 32px;

    button {
      background-color: #545454;
      border: 0;
      padding: 4px 10px;
    }
  }

  .filter-node-color {
    .ant-card-body {
      display: flex;
      align-items: center;
      padding: 10px 16px;
      label {
        margin: 0;
      }
      .anticon-info-circle {
        position: unset;
        margin-left: 1ch;
      }
      .ant-radio-group {
        margin-left: auto;
      }
    }
  }

  .filter-dimension {
    .ant-card-body {
      display: flex;
      align-items: center;
      padding: 10px 16px;
      label {
        margin: 0;
      }
      .discover-graph-mode {
        position: unset !important;
        margin-left: auto;
      }
    }
  }

  .filter-save {
    .ant-card-body {
      padding: 4px 4px 4px 12px;
    }
  }

  .filter-items {
    margin-bottom: 120px;
    overflow: auto;

    .ant-card-body {
      padding: 0;
    }
  }
  .filter-items::-webkit-scrollbar {
    width: 0.6em;
  }
  .filter-items::-webkit-scrollbar-track {
    background: transparent;
  }
  .filter-items::-webkit-scrollbar-thumb {
    background-color: #ffffff;
    border-radius: 30px;
    border: 1px solid black;
  }

  .filter-card.refresh {
    .ant-card-body {
      padding: 8px 24px 0;
    }
  }

  .ant-card-bordered {
    border: 0;
  }

  .network-filter.true {
    animation-duration: 0.4s;
    animation-fill-mode: forwards;
    animation-name: discoverFilterSlideLeft;
    animation-timing-function: ease-out;
    position: absolute;
  }

  .filter-card {
    background-color: #212121;
    width: 100%;

    &.refresh {
      bottom: 0;
      position: absolute;
    }
  }

  .ant-slider-mark-text,
  .ant-slider-mark-text-active {
    color: #ffffff;
  }

  .ant-input {
    flex: 1;
    width: 200px;
  }

  .tooltip-realtime {
    background-color: transparent;
    display: inline-block;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 20px;
    svg {
      margin-top: 2px;
    }
  }

  label {
    color: #ffffff;
    font-size: 12px;
    margin: 0 0 3px;
    vertical-align: top;
  }

  .anticon svg {
    fill: #2d8fa5;
  }

  .anticon-right svg {
    fill: white;
  }

  .anticon-left svg {
    fill: white;
  }

  .filter-view-buttons {
    padding: 22px 3px;
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 22px;

    display: flex;
    align-items: center;
    text-align: center;
  }

  .filter-title {
    background-color: #141414;
    color: #2d8fa5;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    padding: 4px 12px;
    width: 100%;
  }

  .card-title {
    background-color: #141414;
    width: 100%;
  }

  .anticon-info-circle {
    position: absolute;
    right: 12px;
  }

  .filter-refresh {
    background-color: #2d8fa5;
    color: #ffffff;
    margin: 0 auto;
    width: 100%;
  }

  .filter-hscode,
  .filter-products,
  .filter-materials,
  .filter-risk,
  .filter-asset-group,
  .filter-commodities {
    height: auto;
    margin-bottom: 120px;
    width: 100%;
  }

  [class$='-ValueContainer'] {
    max-height: 200px;
    overflow: auto;
  }
  [class$='-MenuList']::-webkit-scrollbar,
  [class$='-ValueContainer']::-webkit-scrollbar {
    margin: 3px 0;
    width: 0.6em;
  }
  [class$='-MenuList']::-webkit-scrollbar-track,
  [class$='-ValueContainer']::-webkit-scrollbar-track {
    background: transparent;
  }
  [class$='-MenuList']::-webkit-scrollbar-thumb,
  [class$='-ValueContainer']::-webkit-scrollbar-thumb {
    background-color: #ffffff;
    border-radius: 0px;
  }

  [class$='-indicatorContainer'] {
    padding: 8px 8px 8px 0;
  }

  button.ant-btn.ant-btn-default.filter-refresh {
    margin-bottom: 8px;
  }

  @keyframes discoverFilterSlideLeft {
    0% {
      right: -350px;
    }
    100% {
      right: 0;
    }
  }
`;

const TooltipIcons = ({ color, text }) => (
  <div className="tooltip-icon">
    <div style={{ backgroundColor: color }} />
    <span>{text}</span>
  </div>
);

const TierTooltipIcons = styled(() => (
  <>
    <div className="tooltip-header">Tiers:</div>
    <div className="tooltip-icons">
      {Object.keys(NODE_COLORS[NODE_COLOR_TIER])
        .sort()
        .map(tier =>
          tier !== 'default' ? (
            <TooltipIcons key={tier} color={NODE_COLORS[NODE_COLOR_TIER][tier]} text={`Tier ${tier}`} />
          ) : null,
        )}
    </div>
    <span>
      Search results with higher incident risk will be<br></br>prioritized when the query limit is exceeded
    </span>
  </>
))``;

const RiskTooltipIcons = styled(() => (
  <>
    <div className="tooltip-header">Risk:</div>
    <div className="tooltip-icons">
      {['Low', 'Medium', 'High'].map(risk => (
        <TooltipIcons key={risk} color={NODE_COLORS[NODE_COLOR_RISK_SCORE][risk.toLowerCase()]} text={risk} />
      ))}
    </div>
    <span>
      Search results with higher proactive<br></br> risk will be prioritized when the query<br></br>limit is exceeded
    </span>
  </>
))``;

const IncidentRiskTooltipIcons = styled(() => (
  <>
    <div className="tooltip-header">Risk:</div>
    <div className="tooltip-icons">
      {['Low', 'Medium', 'High'].map(risk => (
        <TooltipIcons key={risk} color={NODE_COLORS[NODE_COLOR_INCIDENT_RISK_SCORE][risk.toLowerCase()]} text={risk} />
      ))}
    </div>
    <span>
      Search results with higher incident<br></br> risk will be prioritized when the query<br></br>limit is exceeded
    </span>
  </>
))``;

export default NetworkDiscoverFilters;
