import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Row, Col, Collapse, Typography, Form } from 'antd';
import { AppstoreOutlined as GripIcon } from '@ant-design/icons';
import { Label as Input, Numeric, BasicModal as Modal } from '@luxe/components';
import { Button } from '@luxe/components';
import {
  deleteRiskIndex,
  updateRiskIndex,
  createRiskComponent,
  deleteRiskComponent,
  reorderRiskIndex,
  trackRiskModelChange,
} from 'admin/riskmodels/modules/riskmodels';
import TotalCounter from './TotalCounter';
import RiskScoreComponent from './RiskScoreComponent';
import { useTheme } from 'emotion-theming';

const { Text } = Typography;
const { Panel } = Collapse;

const RiskIndexComponent = ({
  key,
  indexArray,
  label,
  weight,
  total,
  components,
  componentTotal,
  deleteRiskIndex,
  updateRiskIndex,
  reorderRiskIndex,
  createRiskComponent,
  deleteRiskComponent,
  activePanel,
  index,
  isEditable,
  showWeightedScoreElement,
  trackRiskModelChange,
  ...props
}) => {
  const { colors, fontSizes } = useTheme();
  const [form] = Form.useForm();
  const [name, setName] = useState(label);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const handleDragStart = e => {
    e.dataTransfer.setData('data', JSON.stringify({ reorderIndex: true, draggedIndexArray: indexArray }));
  };

  useEffect(() => {
    const panel = document.querySelector(`#risk-component-panel-${props.panelKey}`);
    panel.setAttribute('draggable', true);
    panel.addEventListener('dragstart', handleDragStart, false);
    panel.addEventListener('dragover', handleOnDragOver, false);
    panel.addEventListener('drop', handleRiskIndexOnDrop, false);

    return () => {
      panel.removeEventListener('dragstart', handleDragStart, false);
      panel.removeEventListener('dragover', handleOnDragOver, false);
      panel.removeEventListener('drop', handleRiskIndexOnDrop, false);
    };
  });

  const riskIndexChange = name => {
    if (updateRiskIndex) {
      trackRiskModelChange(true);
      setName(name);
      updateRiskIndex(indexArray, { name });
      setIsModalVisible(false);
    }
  };

  const riskIndexDelete = e => {
    if (deleteRiskIndex) {
      trackRiskModelChange(true);
      deleteRiskIndex(indexArray);
    }
  };

  const compareIndexArrays = (fromArray, toArray) => {
    return JSON.stringify(fromArray) === JSON.stringify(toArray);
  };

  const handleRiskIndexOnDrop = e => {
    e.preventDefault();
    const data = JSON.parse(e.dataTransfer.getData('data'));
    if (data.draggingComponent) {
      if (
        !(
          data.newComponent ||
          compareIndexArrays(indexArray, data.draggedIndexArray.slice(0, data.draggedIndexArray.length - 1))
        )
      ) {
        deleteRiskComponent(data.draggedIndexArray);
        createRiskComponent(indexArray, { ...data.panelData });
      }
    } else {
      const { reorderIndex, draggedIndexArray } = data;
      if (reorderIndex) {
        reorderRiskIndex({
          indexArray: indexArray,
          fromIndex: draggedIndexArray.pop(),
          toIndex: indexArray.pop(),
        });
      }
    }
    trackRiskModelChange(true);
  };

  const indexWeightChange = value => {
    trackRiskModelChange(true);
    updateRiskIndex(indexArray, { weight: value });
  };

  const handleDrop = e => {
    e.preventDefault();
    trackRiskModelChange(true);
    const { addComponent, data } = JSON.parse(e.dataTransfer.getData('data'));
    if (addComponent) {
      createRiskComponent(indexArray, { ...data });
    }
  };

  const handleOnDragOver = e => {
    e.preventDefault();
  };

  return (
    <>
      <Panel
        {...props}
        key={index}
        style={{
          minHeight: '32px',
          lineHeight: '22px',
          marginBottom: '5px',
          backgroundColor: colors.black3,
          borderColor: colors.black3,
        }}
        id={`risk-component-panel-${props.panelKey}`}
        header={
          <div style={{ display: 'flex' }}>
            <GripIcon
              width="18px"
              height="18px"
              style={{ color: `${colors.white}`, marginRight: '20px', marginTop: '10px' }}
            />
            <Text
              style={{
                paddingLeft: '10px',
                lineHeight: '32px',
                fontSize: fontSizes[2],
                wordBreak: !name?.includes(' ') ? 'break-all' : 'break-word',
              }}
            >
              {name}
            </Text>
          </div>
        }
        extra={
          <Row style={{ width: '30vw' }} onClick={e => e.stopPropagation()}>
            <Col span={8}>
              {isEditable && (
                <>
                  <Button
                    variant="link"
                    type="link"
                    onClick={() => {
                      setIsModalVisible(true);
                    }}
                  >
                    Edit
                  </Button>
                  <Button variant="link" type="link" onClick={riskIndexDelete}>
                    Delete
                  </Button>
                </>
              )}
            </Col>
            {showWeightedScoreElement() && (
              <Col span={4}>{props.panelKey === activePanel ? <TotalCounter total={componentTotal} /> : null}</Col>
            )}
            <Col span={12}>
              {showWeightedScoreElement() && (
                <Row gutter={16} justify="center" align="middle" style={{ justifyContent: 'flex-end' }}>
                  <Col>
                    <Text>Weight, %</Text>
                  </Col>
                  <Col>
                    <Numeric
                      name="index-weight"
                      key={`index-weight-${key}`}
                      value={weight}
                      onChange={indexWeightChange}
                      style={{ width: '65px' }}
                      max={100}
                      min={0}
                      disabled={!isEditable}
                    />
                  </Col>
                </Row>
              )}
            </Col>
          </Row>
        }
      >
        <div
          onDrop={e => handleDrop(e)}
          onDragOver={e => handleOnDragOver(e)}
          style={{ minHeight: '30px', padding: 0, backgroundColor: colors.black3, borderColor: colors.BG1 }}
        >
          {components &&
            components.map((y, i) => (
              <RiskScoreComponent
                key={y.key}
                weight={y.weight}
                label={y.name}
                indices={y.indices}
                indexArray={y.indexArray}
                data-index={y.indexArray}
                attribute={y.attribute}
                ruleType={y.rule_type}
                rule={y.rule}
                isEditable={isEditable}
                showWeightedScoreElement={showWeightedScoreElement}
                riskScoreId={y.id}
              />
            ))}
          <div
            style={{
              border: `1px dashed ${colors.black3}`,
              padding: '20px',
              borderRadius: '5px',
              color: colors.disabled,
              textAlign: 'center',
              backgroundColor: colors.black5,
              marginTop: '5px',
            }}
          >
            {' '}
            Drop scores here
          </div>
        </div>
      </Panel>
      <Modal
        visible={isModalVisible}
        title="Edit Subrisk Index"
        okText="Save"
        cancelText="Cancel"
        onCancel={() => {
          setIsModalVisible(false);
          form.resetFields();
        }}
        onOk={() => {
          form
            .validateFields()
            .then(({ name }) => {
              riskIndexChange(name);
            })
            .catch(info => {
              console.error('Validate Failed:', info);
            });
        }}
      >
        <Form
          form={form}
          layout="vertical"
          name="edit_risk_subrisk_index_form"
          initialValues={{
            modifier: 'public',
            name: name,
          }}
        >
          <Form.Item
            name="name"
            label={<label style={{ color: 'white' }}>Name</label>}
            rules={[
              {
                required: true,
                message: 'Please input the title of Subrisk Index!',
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

function mapStateToProps({ riskModels, router }) {
  return {
    riskModels,
    location: router.location,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      deleteRiskIndex,
      updateRiskIndex,
      reorderRiskIndex,
      createRiskComponent,
      deleteRiskComponent,
      trackRiskModelChange,
    },
    dispatch,
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(RiskIndexComponent);
