import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Label as Input } from '@luxe/components';
import { Col, Row, Space, Typography } from 'antd';
import { Button, BasicModal as Modal } from '@luxe/components';
import { useTheme } from 'emotion-theming';

import { saveRule } from 'admin/workflows/modules/rules';

const { Text } = Typography;

// TODO: Update header buttons color, implement required data
const HeaderRow = ({ rule, onChange }) => {
  const { space, colors, fontSizes } = useTheme();
  const { selectedIds } = useSelector(store => store.workflowRules);
  const history = useHistory();
  const dispatch = useDispatch();

  const [backModalVisible, setBackModalVisible] = useState(false);
  const cancelClick = () => {
    setBackModalVisible(true);
  };

  const saveClick = () => {
    if (rule.assignment_type === 'Manual') {
      const assetIds = selectedIds.map(s => {
        return { value: s };
      });
      rule.settings.filtering = rule.settings.filtering || { assets: [] };
      rule.settings.filtering.assets = [
        {
          name: 'id',
          operator: 'in',
          value: assetIds,
        },
      ];
    }
    dispatch(saveRule(rule.workflow, rule.id, rule));
    // props.checkIsUpdated(false);
    history.push(`/admin/workflows/${rule.workflow}/network`);
  };

  return (
    <>
      <Row style={{ marginBottom: `${space.small}px` }}>
        <Col span={12} align="start">
          <Input style={{ width: '440px', height: '38px', radius: '4px' }} value={rule.name} onChange={onChange} />
        </Col>
        <Col span={12} align="end">
          <Space>
            <Button variant="alternate" type="link" onClick={cancelClick}>
              Cancel
            </Button>
            <Button variant="primary" type="link" onClick={saveClick} style={{ backgroundColor: colors.primary }}>
              Save
            </Button>
          </Space>
        </Col>
      </Row>
      <Modal
        visible={backModalVisible}
        okText="Yes"
        cancelText="No"
        onCancel={() => {
          setBackModalVisible(false);
        }}
        onOk={() => {
          history.goBack();
        }}
        destroyOnClose={true}
      >
        <Text style={{ fontSize: fontSizes.body, color: colors.white }}>
          You have unsaved edits, do you want to go back?
        </Text>
      </Modal>
    </>
  );
};

export default HeaderRow;
